import { Chapter } from '@models/chapter';
import { ContentItem } from '@models/content-item';

export class MenuItem {
  chapterUuid?: string;
  content: ContentItem[] | null;
  icon: string;
  label: string;
  page: string;
  url?: string;

  constructor(chapter: Chapter) {
    this.chapterUuid = chapter.uuid;
    this.content = chapter.contentItems ? chapter.contentItems.map(item => new ContentItem(item)) : null;
    this.label = `menuitem.${chapter.name}`;
    this.icon = chapter.name;
    this.page = chapter.name;
  }
}
